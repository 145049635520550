/**
 * When one changes the other linked modules are changed to reflect that
 */

const TWO_WAY_LINKS = [
  [
    {
      module: 'liabilities',
      field: 'summary.security',
      multiple: false,
    },
    {
      module: 'assets',
      field: 'summary.linkedLoan',
      multiple: true,
    },
  ],
  [
    {
      module: 'assets',
      field: 'summary.linkedInvestment',
      multiple: false,
    },
    {
      module: 'assets',
      field: 'summary.linkedInvestment',
      multiple: false,
    },
  ],
]

export default function ({ store, $bus, $utils }) {
  $bus.$on('update:success', ({ action, payload, stale }) => {
    /**
     * Currently unused - look at it after launch coz it's spoicy
     * TODO
     * - If linking to something which already has a link, clear the value from the old linked object
     * - Multiple linking: Add/remove/update links from arrays for single to multiple and multiple to multiple
     */
    return

    // const match = action.type.match(/^modules\/(\w+)\/update$/)
    // if (!match) return
    // const currentModule = match[1]

    // // compare payload and current state
    // const twoWayLinks = TWO_WAY_LINKS.filter((v) => v.filter((el) => el?.module === currentModule)?.length)
    // if (!twoWayLinks?.length) return

    // twoWayLinks.forEach(async ([link1, link2]) => {
    //   const linkedModule = link1.module === currentModule ? link2 : link1
    //   const thisModule = link1.module === currentModule ? link1 : link2

    //   let id = payload?.id
    //   let newVal = _.get(payload, thisModule.field, null)
    //   let oldVal = _.get(stale, thisModule.field, null)
    //   if (_.isEqual(oldVal, newVal)) return

    //   const otherModuleStale = _.get(store.state, `modules.${linkedModule.module}.staleData`, false)
    //   // fetch if staleData
    //   if (otherModuleStale) await store.dispatch(`modules/${linkedModule.module}/getAll`)

    //   const otherModuleItems = _.get(store.state, `modules.${linkedModule.module}.allItems`, [])
    //   if (!otherModuleItems?.length) return

    //   // Look for linked object via the newVal first, fallback to old val to nullify
    //   const shouldNullifyLinkedField = newVal ? false : true

    //   // get linked object
    //   const linkedObject = _.find(otherModuleItems, { id: shouldNullifyLinkedField ? oldVal : newVal })
    //   if (!linkedObject) return

    //   const linkedValue = _.get(linkedObject, linkedModule.field, null)
    //   const newLinkedValue = shouldNullifyLinkedField ? null : id

    //   let updatedPayload = null
    //   if (linkedModule?.multiple && !thisModule?.multiple) {
    //     updatedPayload = singleToMultiple(linkedObject, linkedModule.field, linkedValue, newLinkedValue)
    //   } else {
    //     updatedPayload = singleToSingle(linkedObject, linkedModule.field, linkedValue, newLinkedValue)
    //   }

    //   // Payload wasn't updated
    //   if (!updatedPayload) return

    //   // send off store dispatch with modified linked object
    //   // need a slight delay - or an infinite loop will occur, because of the state not being updated in time+
    //   store.dispatch(`modules/${linkedModule.module}/update`, { payload: updatedPayload })
    // })
  })

  function singleToSingle(linkedObject, field, linkedValue, newLinkedValue) {
    // Check if linkedObject field links back to this object already
    if (_.isEqual(linkedValue, newLinkedValue)) return null
    return _.set(_.cloneDeep(linkedObject), field, newLinkedValue)
  }

  function singleToMultiple(linkedObject, field, linkedValue, newLinkedValue) {
    // If it's not an array when it should be or the linked value already exists, do nothing
    if (!Array.isArray(linkedValue) || linkedValue.includes(newLinkedValue)) return null
    return _.set(_.cloneDeep(linkedObject), field, linkedValue.concat([newLinkedValue]))
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_08283616 from 'nuxt_plugin_plugin_08283616' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_4465b420 from 'nuxt_plugin_sentryserver_4465b420' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_7813b468 from 'nuxt_plugin_sentryclient_7813b468' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_42bab598 from 'nuxt_plugin_plugin_42bab598' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_d55bcc58 from 'nuxt_plugin_cookieuniversalnuxt_d55bcc58' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_nuxtsocketio_5fa346b6 from 'nuxt_plugin_nuxtsocketio_5fa346b6' // Source: ./nuxt-socket-io.js (mode: 'all')
import nuxt_plugin_moment_bc28f628 from 'nuxt_plugin_moment_bc28f628' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_workbox_568eec48 from 'nuxt_plugin_workbox_568eec48' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_737b6b5b from 'nuxt_plugin_metaplugin_737b6b5b' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_axios_2e0a522c from 'nuxt_plugin_axios_2e0a522c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_deviceplugin_001a0492 from 'nuxt_plugin_deviceplugin_001a0492' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_moment_6f2f8c1d from 'nuxt_plugin_moment_6f2f8c1d' // Source: ../plugins/thirdParty/moment.js (mode: 'all')
import nuxt_plugin_html2canvas_d406019c from 'nuxt_plugin_html2canvas_d406019c' // Source: ../plugins/thirdParty/html2canvas.js (mode: 'all')
import nuxt_plugin_googleplaces_64b9be9e from 'nuxt_plugin_googleplaces_64b9be9e' // Source: ../plugins/thirdParty/google-places.js (mode: 'all')
import nuxt_plugin_syncfusion_72782b4a from 'nuxt_plugin_syncfusion_72782b4a' // Source: ../plugins/thirdParty/sync-fusion.js (mode: 'all')
import nuxt_plugin_grafanaclient_f382eef0 from 'nuxt_plugin_grafanaclient_f382eef0' // Source: ../plugins/thirdParty/grafana.client.js (mode: 'client')
import nuxt_plugin_lodash_0f7eb330 from 'nuxt_plugin_lodash_0f7eb330' // Source: ../plugins/thirdParty/lodash.js (mode: 'all')
import nuxt_plugin_uuid_93c363fc from 'nuxt_plugin_uuid_93c363fc' // Source: ../plugins/thirdParty/uuid.js (mode: 'all')
import nuxt_plugin_utils_315ee134 from 'nuxt_plugin_utils_315ee134' // Source: ../plugins/helpers/utils.js (mode: 'all')
import nuxt_plugin_navigation_3d9eefa3 from 'nuxt_plugin_navigation_3d9eefa3' // Source: ../plugins/helpers/navigation.js (mode: 'all')
import nuxt_plugin_forms_4fed09b0 from 'nuxt_plugin_forms_4fed09b0' // Source: ../plugins/helpers/forms.js (mode: 'all')
import nuxt_plugin_db_648a1cf9 from 'nuxt_plugin_db_648a1cf9' // Source: ../plugins/helpers/db.js (mode: 'all')
import nuxt_plugin_calculations_51f2a9a6 from 'nuxt_plugin_calculations_51f2a9a6' // Source: ../plugins/helpers/calculations.js (mode: 'all')
import nuxt_plugin_eventbus_60741c6c from 'nuxt_plugin_eventbus_60741c6c' // Source: ../plugins/helpers/event-bus.js (mode: 'all')
import nuxt_plugin_logs_2afdfa28 from 'nuxt_plugin_logs_2afdfa28' // Source: ../plugins/helpers/logs.js (mode: 'all')
import nuxt_plugin_draggable_dd863138 from 'nuxt_plugin_draggable_dd863138' // Source: ../plugins/thirdParty/draggable.js (mode: 'all')
import nuxt_plugin_common_63bd4c6c from 'nuxt_plugin_common_63bd4c6c' // Source: ../plugins/helpers/common.js (mode: 'all')
import nuxt_plugin_webstompclient_243757fd from 'nuxt_plugin_webstompclient_243757fd' // Source: ../plugins/thirdParty/web-stomp-client.js (mode: 'all')
import nuxt_plugin_axios_e0ffbe4a from 'nuxt_plugin_axios_e0ffbe4a' // Source: ../plugins/thirdParty/axios.js (mode: 'all')
import nuxt_plugin_customcomponents_6537c985 from 'nuxt_plugin_customcomponents_6537c985' // Source: ../plugins/helpers/custom-components.js (mode: 'all')
import nuxt_plugin_localCache_43785800 from 'nuxt_plugin_localCache_43785800' // Source: ../plugins/helpers/localCache.js (mode: 'all')
import nuxt_plugin_sessionRefresh_221a58b2 from 'nuxt_plugin_sessionRefresh_221a58b2' // Source: ../plugins/helpers/sessionRefresh.js (mode: 'all')
import nuxt_plugin_fees_47281d2c from 'nuxt_plugin_fees_47281d2c' // Source: ../plugins/helpers/fees.js (mode: 'all')
import nuxt_plugin_vuesignature_d9563f80 from 'nuxt_plugin_vuesignature_d9563f80' // Source: ../plugins/thirdParty/vue-signature.js (mode: 'all')
import nuxt_plugin_animejs_66ef54b4 from 'nuxt_plugin_animejs_66ef54b4' // Source: ../plugins/thirdParty/animejs.js (mode: 'all')
import nuxt_plugin_auth_271f3cf8 from 'nuxt_plugin_auth_271f3cf8' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_auth_d682fe16 from 'nuxt_plugin_auth_d682fe16' // Source: ../plugins/thirdParty/auth.js (mode: 'all')
import nuxt_plugin_libnuxtclientinitpluginclient30ac4112_4feea100 from 'nuxt_plugin_libnuxtclientinitpluginclient30ac4112_4feea100' // Source: ./lib.nuxt-client-init.plugin.client.30ac4112.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Elan2","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""}],"link":[{"hid":"icon","rel":"icon","type":"image\u002Fsvg","href":"\u002Ficons\u002FElan.svg"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Roboto:100,300,400,500,700,900&display=swap"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002F@mdi\u002Ffont@latest\u002Fcss\u002Fmaterialdesignicons.min.css"}],"script":[{"src":"https:\u002F\u002Fmaps.googleapis.com\u002Fmaps\u002Fapi\u002Fjs?key=AIzaSyCDmT9PtlCOVs_34d0sDAfRdfL9GfofE38&libraries=places&callback=Function.prototype","defer":true,"async":true},{"src":"https:\u002F\u002Fdigitalid-sandbox.com\u002Fsdk\u002Fapp.js","defer":true,"async":true},{"src":"https:\u002F\u002Faccounts.google.com\u002Fgsi\u002Fclient","defer":true,"async":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_08283616 === 'function') {
    await nuxt_plugin_plugin_08283616(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_4465b420 === 'function') {
    await nuxt_plugin_sentryserver_4465b420(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_7813b468 === 'function') {
    await nuxt_plugin_sentryclient_7813b468(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_42bab598 === 'function') {
    await nuxt_plugin_plugin_42bab598(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_d55bcc58 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_d55bcc58(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsocketio_5fa346b6 === 'function') {
    await nuxt_plugin_nuxtsocketio_5fa346b6(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_bc28f628 === 'function') {
    await nuxt_plugin_moment_bc28f628(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_568eec48 === 'function') {
    await nuxt_plugin_workbox_568eec48(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_737b6b5b === 'function') {
    await nuxt_plugin_metaplugin_737b6b5b(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2e0a522c === 'function') {
    await nuxt_plugin_axios_2e0a522c(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_001a0492 === 'function') {
    await nuxt_plugin_deviceplugin_001a0492(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_6f2f8c1d === 'function') {
    await nuxt_plugin_moment_6f2f8c1d(app.context, inject)
  }

  if (typeof nuxt_plugin_html2canvas_d406019c === 'function') {
    await nuxt_plugin_html2canvas_d406019c(app.context, inject)
  }

  if (typeof nuxt_plugin_googleplaces_64b9be9e === 'function') {
    await nuxt_plugin_googleplaces_64b9be9e(app.context, inject)
  }

  if (typeof nuxt_plugin_syncfusion_72782b4a === 'function') {
    await nuxt_plugin_syncfusion_72782b4a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_grafanaclient_f382eef0 === 'function') {
    await nuxt_plugin_grafanaclient_f382eef0(app.context, inject)
  }

  if (typeof nuxt_plugin_lodash_0f7eb330 === 'function') {
    await nuxt_plugin_lodash_0f7eb330(app.context, inject)
  }

  if (typeof nuxt_plugin_uuid_93c363fc === 'function') {
    await nuxt_plugin_uuid_93c363fc(app.context, inject)
  }

  if (typeof nuxt_plugin_utils_315ee134 === 'function') {
    await nuxt_plugin_utils_315ee134(app.context, inject)
  }

  if (typeof nuxt_plugin_navigation_3d9eefa3 === 'function') {
    await nuxt_plugin_navigation_3d9eefa3(app.context, inject)
  }

  if (typeof nuxt_plugin_forms_4fed09b0 === 'function') {
    await nuxt_plugin_forms_4fed09b0(app.context, inject)
  }

  if (typeof nuxt_plugin_db_648a1cf9 === 'function') {
    await nuxt_plugin_db_648a1cf9(app.context, inject)
  }

  if (typeof nuxt_plugin_calculations_51f2a9a6 === 'function') {
    await nuxt_plugin_calculations_51f2a9a6(app.context, inject)
  }

  if (typeof nuxt_plugin_eventbus_60741c6c === 'function') {
    await nuxt_plugin_eventbus_60741c6c(app.context, inject)
  }

  if (typeof nuxt_plugin_logs_2afdfa28 === 'function') {
    await nuxt_plugin_logs_2afdfa28(app.context, inject)
  }

  if (typeof nuxt_plugin_draggable_dd863138 === 'function') {
    await nuxt_plugin_draggable_dd863138(app.context, inject)
  }

  if (typeof nuxt_plugin_common_63bd4c6c === 'function') {
    await nuxt_plugin_common_63bd4c6c(app.context, inject)
  }

  if (typeof nuxt_plugin_webstompclient_243757fd === 'function') {
    await nuxt_plugin_webstompclient_243757fd(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_e0ffbe4a === 'function') {
    await nuxt_plugin_axios_e0ffbe4a(app.context, inject)
  }

  if (typeof nuxt_plugin_customcomponents_6537c985 === 'function') {
    await nuxt_plugin_customcomponents_6537c985(app.context, inject)
  }

  if (typeof nuxt_plugin_localCache_43785800 === 'function') {
    await nuxt_plugin_localCache_43785800(app.context, inject)
  }

  if (typeof nuxt_plugin_sessionRefresh_221a58b2 === 'function') {
    await nuxt_plugin_sessionRefresh_221a58b2(app.context, inject)
  }

  if (typeof nuxt_plugin_fees_47281d2c === 'function') {
    await nuxt_plugin_fees_47281d2c(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesignature_d9563f80 === 'function') {
    await nuxt_plugin_vuesignature_d9563f80(app.context, inject)
  }

  if (typeof nuxt_plugin_animejs_66ef54b4 === 'function') {
    await nuxt_plugin_animejs_66ef54b4(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_271f3cf8 === 'function') {
    await nuxt_plugin_auth_271f3cf8(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_d682fe16 === 'function') {
    await nuxt_plugin_auth_d682fe16(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_libnuxtclientinitpluginclient30ac4112_4feea100 === 'function') {
    await nuxt_plugin_libnuxtclientinitpluginclient30ac4112_4feea100(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }

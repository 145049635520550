import _ from 'lodash'
import Vue from 'vue'

export const state = () => ({
  backendClasses: {},
  blankStatusTable: null,
  tempData: {},
  // Generic dispatch debounce
  debounceAction: _.debounce((dispatch, dispatchString, arg) => {
    dispatch(dispatchString, arg, { root: true })
  }, 1500),
})

export const mutations = {
  modifyCollection: (state, { collection, item, action }) => {
    if (!collection) {
      console.warn('Collection undefined or null in storeUtils.modifyCollection')
      return
    }

    switch (action) {
      case 'delete':
        item.forEach((id) => {
          collection.find((collectionItem, index) => {
            if (collectionItem && collectionItem.id === id) {
              collection.splice(index, 1)
            }
          })
        })
        break
      case 'add':
        if (Array.isArray(item)) collection.push(...item)
        else collection.push(item)
        break
      case 'update':
        let exists = _.findIndex(collection, (collectionItem) => (!_.isNil(item?.id) ? collectionItem?.id === item?.id : !_.isNil(item?.user_id) ? collectionItem.user_id === item?.user_id : false))
        if (exists == -1) collection.push(item)
        else Vue.set(collection, exists, item)
        break
      case 'idReplace':
        if (Array.isArray(collection)) {
          collection.find((collectionItem) => {
            if (collectionItem.id === item.old) {
              collectionItem.id = item.new
              return true
            }
          })
        } else {
          if (collection.id === item.old) {
            collection.id = item.new
          }
        }
        break
      case 'insert':
        try {
          Object.keys(item).forEach((key) => {
            Vue.set(collection, key, item[key])
          })
        } catch (err) {
          console.warn('StoreUils.insert', err)
          //Dont worry about it
        }
        break
      case 'restore':
        item.forEach((i) => {
          collection.find((collectionItem) => {
            if (collectionItem.id === i.id) {
              if (collectionItem.deleted !== undefined) {
                Vue.delete(collectionItem, 'deleted')
              }
            }
          })
        })
        break
      case 'archive':
        item.forEach((i) => {
          collection.find((collectionItem) => {
            if (collectionItem && collectionItem.id === i.id) {
              Vue.set(collectionItem, 'deleted', 'ARCHIVED')
            }
          })
        })
        break
      default:
        console.log('No Action listed')
    }
  },
  setItem: (state, { localState, item, value }) => {
    _.set(localState, item, value)
  },
  clearTempData: (state) => {
    _.set(state, 'tempData', {})
  },
  select: (state, { localState, data }) => {
    if (!data || data.id === null) {
      // Deselect
      localState.selected = null
      localState.selectedIndex = null
      localState.selectedOption = null
    } else {
      if (localState.newEdit != null && data.id == localState.newEdit.id) {
        // Select new edit
        localState.selected = localState.newEdit
      } else if (Array.isArray(data?.dataList) && data.dataList.length > 0) {
        // Find then select based on ID
        localState.selected = data.dataList.find((item, index) => {
          if (item.id == data.id) {
            localState.selectedIndex = index
            return item
          }
        })
      }

      if (localState.subOptions) {
        let availableOptions = null
        // Options are already an array
        if (Array.isArray(localState.subOptions)) availableOptions = localState.subOptions
        // Options are an object but only one possible selection so get that selection
        else if (Object.values(localState.subOptions).length === 1) availableOptions = Object.values(localState.subOptions)[0]
        // Options are based on selected type
        else if (localState.selected?.type && Array.isArray(localState.subOptions[localState.selected.type.toLowerCase()])) availableOptions = localState.subOptions[localState.selected.type.toLowerCase()]

        // Add any extra options to the pool
        if (data.extraOptions) availableOptions = availableOptions.concat(data.extraOptions)

        // If there are available options, determine if the selected option needs to be reset
        if (availableOptions) {
          // New edit or no selected option
          if (localState.newEdit != null || (localState.selected && !localState.selectedOption)) {
            localState.selectedOption = availableOptions[0]
            // New edit with type or selected doesn't have an available option
          } else if ((localState.newEdit != null && localState.newEdit.type) || (localState.selected && localState.selected.type && !availableOptions.find((el) => el.value === localState.selectedOption?.value))) {
            localState.selectedOption = availableOptions[0]
            // New edit without type or selected has no type and no option is selected
          } else if ((localState.newEdit != null && !localState.newEdit.type) || (localState.selected && !localState.selected.type && !localState.selectedOption)) {
            localState.selectedOption = availableOptions[0]
          }
        }
      }

      // Default to first sub option if the currently selected one does not exist or a new edit is in progress
      // if (localState.subOptions) {
      //   if (Array.isArray(localState.subOptions) && (localState.newEdit != null || (localState.selected && !localState.selectedOption))) {
      //     localState.selectedOption = localState.subOptions[0]
      //   } else if ((localState.newEdit != null && localState.newEdit.type) || (localState.selected && localState.selected.type && !localState.subOptions[localState.selected.type.toLowerCase()].includes(localState.selectedOption))) {
      //     localState.selectedOption = Object.values(localState.subOptions).length === 1 ? Object.values(localState.subOptions)[0][0] : localState.subOptions[localState.selected.type.toLowerCase()][0]
      //   } else if ((localState.newEdit != null && !localState.newEdit.type) || (localState.selected && !localState.selected.type && !localState.selectedOption)) {
      //     localState.selectedOption = Object.values(localState.subOptions)[0][0]
      //   }
      // }
    }
  },
}

export const getters = {
  getBackendClass: (state) => (className) => {
    return _.get(state, `backendClasses[${className}]`)
  },
  getDeletedRowSnapshot: (state) => (data) => {
    return state.tempData[data.groupIndex]
  },
  getDeletedTableSnapshot: (state) => () => {
    return state.tempData.deletedTableSnapshot
  },
  getDeletedObject: (state) => () => {
    return state.tempData
  },
}

export const actions = {
  debounceAction({ state, dispatch }, { dispatchString, arg }) {
    state.debounceAction(dispatch, dispatchString, arg)
  },
  async getBlankStatusTable({ state, dispatch }) {
    if (!state.blankStatusTable) await dispatch('application/storeUtils/fetchBlank', { localState: state, item: 'blankStatusTable', endpoint: '/api/v1/statusTable/new' }, { root: true })
  },
  async getBackendClass({ state, commit }, className) {
    if (!_.isNil(className) && !_.get(state, `backendClasses[${className}]`)) {
      let blankClass = await this.$axios.$get(`/api/v1/general/blank/${className}`)
      // Set dates which should be the current date
      switch (className) {
        case 'FileNote':
        case 'Goals':
          blankClass.date = new Date()
          break
        case 'Notification':
          blankClass.creationDate = new Date()
          break
        case 'Kanban':
          blankClass.created = new Date()
          break
      }
      commit('setItem', { localState: state, item: `backendClasses[${className}]`, value: blankClass })
    }
    return _.cloneDeep(_.get(state, `backendClasses[${className}]`))
  },
  async fetchBlank({ commit }, { localState, item, endpoint }) {
    if (localState[item] === null) {
      // Set item to undefined while fetching so that a loader can be displayed if it equals undefined
      commit('setItem', { localState: localState, item: item, value: undefined })
      commit('setItem', { localState: localState, item: item, value: (await this.$axios.get(endpoint)).data })
    }
  },
  async getAll({ dispatch }, { page, queryData }) {
    await dispatch(page + '/getAll', '', { root: true }).then(() => {
      if (queryData) dispatch(page + '/select', queryData, { root: true })
    })
  },
  select({ commit, dispatch, rootState }, { localState, data, replaceFromId }) {
    if (replaceFromId) {
      commit('select', { localState, data })
      // Convert to array if not already an array
      if (!Array.isArray(replaceFromId)) replaceFromId = [replaceFromId]
      replaceFromId.forEach((toReplace) => {
        // Fetch objects from ids
        if (localState.selected && typeof localState.selected[toReplace.key] === 'string') {
          if (rootState.externalData.fetchedProviders) {
            // Get from providers if they've already been fetched
            let found = rootState.externalData.providers.find((el) => el.id === localState.selected[toReplace.key])
            if (found) commit('setItem', { localState: localState, item: 'selected.' + toReplace.key, value: found })
          } else {
            dispatch(toReplace.action, localState.selected[toReplace.key], { root: true }).then((res) => {
              commit('setItem', { localState: localState, item: 'selected.' + toReplace.key, value: res })
            })
          }
        }
      })
    } else {
      commit('select', { localState, data })
    }
  },
  setTempData({ state, commit }, { data }) {
    commit('setItem', { localState: state, item: 'tempData', value: data })
  },
}

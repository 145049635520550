import { _ } from 'core-js'
import CrudStore from './crudStore'

//Slight modification to abort any current ones if its called before a previous one hasn't finished
function newAbortSignal(commit, state, timeoutMs) {
  if (state.abortController) {
    state.abortController.abort()
  }
  commit('updateAbortController', new AbortController())
  setTimeout(() => state.abortController?.abort(), timeoutMs || 0)

  return state.abortController.signal
}

// Returns the ordinal suffix for a day number (e.g., "st" for 1, "nd" for 2)
function getOrdinal(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}

// Formats a date as "Wednesday 20th of Nov"
function formatDate(dateInput) {
  const date = $nuxt.$moment(dateInput);
  const dayName = date.format('dddd');       // e.g., "Wednesday"
  const dayNumber = date.date();               // e.g., 20
  const ordinal = getOrdinal(dayNumber);       // e.g., "th"
  const monthShort = date.format('MMM');       // e.g., "Nov"
  return `${dayName} ${dayNumber}${ordinal} of ${monthShort}`;
}

// Determines the date range category for a task based on its due date.
// If no due date exists, returns "No Due Date".
// If the due date is before today, returns "Overdue".
// If the due date is today or tomorrow, returns "Today" or "Tomorrow".
// Otherwise, it returns a formatted date string.
function determineDateRangeCategory(task) {
  if (!task.dueDate) {
    return 'No Due Date';
  }

  // Convert task.dueDate to a Moment object and work with just the date part.
  const dueDate = $nuxt.$moment(task.dueDate).startOf('day');
  const today = $nuxt.$moment().startOf('day');
  const tomorrow = $nuxt.$moment().add(1, 'days').startOf('day');

  if (dueDate.isBefore(today)) {
    return 'Overdue';
  } else if (dueDate.isSame(today)) {
    return 'Today';
  } else if (dueDate.isSame(tomorrow)) {
    return 'Tomorrow';
  } else {
    return formatDate(dueDate);
  }
}


const defaultPageData = {
  content: [],
  empty: null,
  first: null,
  last: null,
  number: -1,
  numberOfElements: -1,
  pageable: {},
  size: 50,
  sort: { sorted: null, unsorted: null, empty: null },
  totalElements: -1,
  totalPages: -1,
}

export default class extends CrudStore {
  constructor(endpoint, typeSelections, singlePage, organisation) {
    super(endpoint, typeSelections, singlePage, organisation)

    /**
     * @type {import('../../../types/store').FilterState & import('../../../types/store').CrudState}
     */
    this.state = {
      ...this.state,
      segmentedTableData: [],
      tableDisplayIds: new Set(), // IDs for objects to be displayed in the table
      pageData: defaultPageData,
      paginationLoading: false,
      abortController: null,
      latestFilter: null, // Latest filter JSON, used to check if new objects match the filter criteria and can be shown or not
    }

    /**
     * @type { import('../../../types/store').Getters<import('../../../types/store').FilterState & import('../../../types/store').CrudState> }
     */
    this.getters = {
      ...this.getters,
      getSegmentedTableItems(state) {
        return state.segmentedTableData
      },
      getTableItems(state) {
        let tableItems = state.allItems.filter((el) => state.tableDisplayIds.has(el?.id) && (!el.deleted || el.deleted === 'ARCHIVED') && !el.isTemplate)
        if (!Array.isArray(tableItems)) return []
        if (_.isNil(state.typeSelections)) return tableItems
        return tableItems.toSorted((a, b) => {
          const typeA = state.typeSelections.find((type) => type.value === a.type)
          const typeB = state.typeSelections.find((type) => type.value === b.type)

          const indexA = state.typeSelections.indexOf(typeA)
          const indexB = state.typeSelections.indexOf(typeB)

          // Compare the indices to determine the sorting order
          return indexA - indexB
        })
      },
    }

    /**
     * @type { import('../../../types/store').Mutations<import('../../../types/store').FilterState & import('../../../types/store').CrudState> }
     */
    this.mutations = {
      ...this.mutations,
      mergeSegmentedTableData(state, { data, freshSearch }) {
        if (freshSearch) {
          state.segmentedTableData = []
        }
        data.forEach(({ dateRange, statuses }) => {
          let dateRangeIndex = _.findIndex(state.segmentedTableData, { dateRange })
          if (dateRangeIndex != -1) {
            statuses.forEach(({ status, tasks }) => {
              let statusIndex = _.findIndex(state.segmentedTableData[dateRangeIndex].statuses, { status })

              if (statusIndex != -1) {
                state.segmentedTableData[dateRangeIndex].statuses[statusIndex].tasks.push(...tasks)
              } else if (state.segmentedTableData[dateRangeIndex].statuses.length != 0) {
                state.segmentedTableData[dateRangeIndex].statuses.push({ status, tasks })
              } else {
                state.segmentedTableData[dateRangeIndex].statuses = [{ status, tasks }]
              }
            })
          } else if (state.segmentedTableData.length != 0) {
            state.segmentedTableData.push({ dateRange, statuses })
          } else {
            state.segmentedTableData = [{ dateRange, statuses }]
          }
        })
      },
      setPageData(state, pageData) {
        state.pageData = pageData
        // if(_.has(state.pageData,'number')){
        //   const newQuery = {
        //     ...$nuxt.$route.query,
        //     page: state.pageData.number
        //   }
        //   $nuxt.$router.push({ query: newQuery })
        // }
      },
      updateObjectInCurrentContent(state, item) {
        // Format the new date according to your desired format
        const newDateRange = determineDateRangeCategory(item)

        // Loop over each dateGroup and each statusGroup inside it
        let found = false
        let pageDataClone = state.pageData
        for (const dateGroup of pageDataClone.content) {
          for (const statusGroup of dateGroup.statuses) {
            // Find the task by id in the tasks array
            const taskIndex = statusGroup.tasks.findIndex((task) => task.id === item.id)
            if (taskIndex !== -1) {
              const existingTask = statusGroup.tasks[taskIndex]

              // Determine if the date or status has changed.
              // For date comparison, we compare the formatted version
              const existingDateRange = determineDateRangeCategory(existingTask)
              const dateChanged = existingDateRange !== newDateRange
              const statusChanged = existingTask.status !== item.status
              if (dateChanged || statusChanged) {
                // Remove the task from its current location
                statusGroup.tasks.splice(taskIndex, 1)

                // Find the new date group for the updated date
                let newDateGroup = state.pageData.content.find((group) => group.dateRange === newDateRange)
                if (!newDateGroup && state.pageData.content.length > 1) {
                  // If the new date group doesn't exist, return false
                  state.requiresRefresh = true
                  return false
                }

                if( state.pageData.content.length == 1){
                  newDateGroup = state.pageData.content[0]
                }

                // Find the appropriate status group within the new date group
                let newStatusGroup = newDateGroup.statuses.find((group) => group.status === item.status)
                if (!newStatusGroup && !_.isNil(newDateGroup.statuses[0].status)) {
                  // If the new status group doesn't exist, return false
                  state.requiresRefresh = true
                  return false
                } else if(_.isNil(newDateGroup.statuses[0].status)){
                  newStatusGroup = newDateGroup.statuses[0]
                }
                // Insert the updated task into the correct status group's tasks array
                newStatusGroup.tasks.push({ ...existingTask, ...item })
              } else {
                // If neither date nor status has changed, simply update the task in place
                statusGroup.tasks.splice(taskIndex, 1, { ...existingTask, ...item })
              }

              // Since we've found and updated the task, mark it as found and exit the loops.
              found = true
              break
            }
          }
          if (found) break
        }
      },
      setPaginationLoading(state, loading) {
        state.paginationLoading = loading
      },
      updateAbortController(state, controller) {
        state.abortController = controller
      },
      resetPageData(state) {
        state.pageData = _.cloneDeep(defaultPageData)
      },
      addDisplayIds(state, ids) {
        if (!Array.isArray(ids)) ids = [ids]
        ids = ids.filter((el) => el) // Remove null/undefined
        ids.forEach((id) => state.tableDisplayIds.add(id))
      },
      removeDisplayIds(state, ids) {
        if (!Array.isArray(ids)) ids = [ids]
        ids = ids.filter((el) => el) // Remove null/undefined
        ids.forEach((id) => state.tableDisplayIds.delete(id))
      },
      setLatestFilter(state, filterJson) {
        state.latestFilter = filterJson
      },
      deleteObjectInCurrentContent(state, ids) {
        // Find and update the task while ensuring reactivity
        ids.forEach((id) => {
          state.pageData.content.forEach((dateGroup) => {
            if (!dateGroup?.statuses) return
            dateGroup.statuses.forEach((statusGroup) => {
              const taskIndex = statusGroup.tasks.findIndex((task) => task.id === id)
              if (taskIndex !== -1) {
                statusGroup.tasks.splice(taskIndex, 1)
              }
            })
          })
        })
      },
    }

    /**
     * @type { import('../../../types/store').Actions<import('../../../types/store').FilterState & import('../../../types/store').CrudState> }
     */
    this.actions = {
      ...this.actions,
      // Fetch by ID with filters applied to see if it's an object that should be shown or not
      async getOneFiltered({ state, commit }, id) {
        let obj = null
        if (state.latestFilter) obj = await this.$axios.$post(`/api/v1/${state.endpoint}/filter/${id}`, state.latestFilter)
        else obj = await this.$axios.$get(`/api/v1/${state.endpoint}/${id}`)
        if (obj) commit(`addDisplayIds`, obj?.id)
        return !!obj
      },
      async getAll({ state, commit, getters }, payload /* { searchJson, freshSearch, doubleStuff } */) {
        if (payload === undefined) {
          // If its undefined assume its just trying to do a normal get all for hyperlink labels or something
          try {
            commit('application/loading/setLoading', true, { root: true })
            await this.$axios
              .$get(`/api/v1/${state.endpoint}`)
              .then((result) => {
                commit('mergeItems', result)
                commit('application/storeUtils/setItem', { localState: state, item: 'tableDisplayIds', value: new Set(result.map((el) => el.id)) }, { root: true })
                commit('application/storeUtils/setItem', { localState: state, item: 'staleData', value: false }, { root: true })
              })
              .catch((err) => {
                throw err
              })
              .finally(() => {
                commit('application/loading/setLoading', false, { root: true })
              })
          } catch (e) {
            console.warn(state.endpoint + ' invalid getAll usage')
          }
          return getters.getAllItems
        }
        const { searchJson = {}, freshSearch = false, doubleStuff = false } = payload
        if (freshSearch) commit('resetPageData') // fresh query - ensure pageData uses defaults

        const body = _.assign({ page: freshSearch ? 0 : state.pageData.number + 1 }, searchJson)

        if (state.pageData.size !== -1) {
          body.size = searchJson?.size ?? state.pageData.size
        }
        commit('setPaginationLoading', true)
        const data = await this.$axios.$post(`/api/v1/${state.endpoint}/${doubleStuff == true ? 'doubleStuff' : 'filter'}`, body, { signal: newAbortSignal(commit, state, 10000) }).catch(() => {})

        /* User canceled by loading a new filter while waiting for this one */
        commit('updateAbortController', null)
        commit('setPaginationLoading', false)
        if (data) {
          if (!_.isNil(data.mutableTotal)) data.total = data.mutableTotal
          commit('setPageData', data)
          let mappedData = data.content
          if (doubleStuff == true) {
            commit('mergeSegmentedTableData', { data: data.content, freshSearch })
            // Flatten and extract tasks
            const flattenedTasks = _.reduce(
              data.content,
              (acc, el) => {
                const flatTasks = el.statuses.flatMap(({ tasks }) => tasks)
                commit('mergeItems', flatTasks)
                return acc.concat(flatTasks)
              },
              []
            )

            mappedData = flattenedTasks.map((el) => el.id)
          } else {
            commit('mergeItems', data.content)
          }

          freshSearch
            ? commit('application/storeUtils/setItem', { localState: state, item: 'tableDisplayIds', value: new Set(data.content.map((el) => el.id)) }, { root: true })
            : commit(
                'addDisplayIds',
                data.content.map((el) => el.id)
              )

          if (state.staleData) {
            commit('application/storeUtils/setItem', { localState: state, item: 'staleData', value: false }, { root: true })
          }

          return data.content
        }
      },
    }
  }
}

export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const ErrorPage = () => import('../../components/shared/pages/ErrorPage.vue' /* webpackChunkName: "components/error-page" */).then(c => wrapFunctional(c.default || c))
export const ApplicationColumnChart = () => import('../../components/application/charts/ApplicationColumnChart.vue' /* webpackChunkName: "components/application-column-chart" */).then(c => wrapFunctional(c.default || c))
export const ApplicationStackedColumnChart = () => import('../../components/application/charts/ApplicationStackedColumnChart.vue' /* webpackChunkName: "components/application-stacked-column-chart" */).then(c => wrapFunctional(c.default || c))
export const ApplicationChangeLogButton = () => import('../../components/application/button/ApplicationChangeLogButton.vue' /* webpackChunkName: "components/application-change-log-button" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDeleteButton = () => import('../../components/application/button/ApplicationDeleteButton.vue' /* webpackChunkName: "components/application-delete-button" */).then(c => wrapFunctional(c.default || c))
export const ApplicationFilterButton = () => import('../../components/application/button/ApplicationFilterButton.vue' /* webpackChunkName: "components/application-filter-button" */).then(c => wrapFunctional(c.default || c))
export const ApplicationHeaderButton = () => import('../../components/application/button/ApplicationHeaderButton.vue' /* webpackChunkName: "components/application-header-button" */).then(c => wrapFunctional(c.default || c))
export const ApplicationModuleButton = () => import('../../components/application/button/ApplicationModuleButton.vue' /* webpackChunkName: "components/application-module-button" */).then(c => wrapFunctional(c.default || c))
export const ApplicationPrivacyButton = () => import('../../components/application/button/ApplicationPrivacyButton.vue' /* webpackChunkName: "components/application-privacy-button" */).then(c => wrapFunctional(c.default || c))
export const ApplicationXeroButton = () => import('../../components/application/button/ApplicationXeroButton.vue' /* webpackChunkName: "components/application-xero-button" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDifferenceCard = () => import('../../components/application/card/ApplicationDifferenceCard.vue' /* webpackChunkName: "components/application-difference-card" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDifferenceContent = () => import('../../components/application/card/ApplicationDifferenceContent.vue' /* webpackChunkName: "components/application-difference-content" */).then(c => wrapFunctional(c.default || c))
export const ApplicationInfoCard = () => import('../../components/application/card/ApplicationInfoCard.vue' /* webpackChunkName: "components/application-info-card" */).then(c => wrapFunctional(c.default || c))
export const ApplicationKnowledgeBaseCard = () => import('../../components/application/card/ApplicationKnowledgeBaseCard.vue' /* webpackChunkName: "components/application-knowledge-base-card" */).then(c => wrapFunctional(c.default || c))
export const ApplicationNotificationCard = () => import('../../components/application/card/ApplicationNotificationCard.vue' /* webpackChunkName: "components/application-notification-card" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSimpleDataCard = () => import('../../components/application/card/ApplicationSimpleDataCard.vue' /* webpackChunkName: "components/application-simple-data-card" */).then(c => wrapFunctional(c.default || c))
export const ApplicationChecklistActionsDialog = () => import('../../components/application/dialog/ApplicationChecklistActionsDialog.vue' /* webpackChunkName: "components/application-checklist-actions-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCreateGroupDialog = () => import('../../components/application/dialog/ApplicationCreateGroupDialog.vue' /* webpackChunkName: "components/application-create-group-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDeleteDialog = () => import('../../components/application/dialog/ApplicationDeleteDialog.vue' /* webpackChunkName: "components/application-delete-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDialog = () => import('../../components/application/dialog/ApplicationDialog.vue' /* webpackChunkName: "components/application-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDifferenceDialog = () => import('../../components/application/dialog/ApplicationDifferenceDialog.vue' /* webpackChunkName: "components/application-difference-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDocumentSearchDialog = () => import('../../components/application/dialog/ApplicationDocumentSearchDialog.vue' /* webpackChunkName: "components/application-document-search-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationEventsTransactionsDialog = () => import('../../components/application/dialog/ApplicationEventsTransactionsDialog.vue' /* webpackChunkName: "components/application-events-transactions-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationKeybindsDialog = () => import('../../components/application/dialog/ApplicationKeybindsDialog.vue' /* webpackChunkName: "components/application-keybinds-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationManagePortalDialog = () => import('../../components/application/dialog/ApplicationManagePortalDialog.vue' /* webpackChunkName: "components/application-manage-portal-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationOwnershipDialog = () => import('../../components/application/dialog/ApplicationOwnershipDialog.vue' /* webpackChunkName: "components/application-ownership-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSideCard = () => import('../../components/application/dialog/ApplicationSideCard.vue' /* webpackChunkName: "components/application-side-card" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSingleCreateDialog = () => import('../../components/application/dialog/ApplicationSingleCreateDialog.vue' /* webpackChunkName: "components/application-single-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationStompDialog = () => import('../../components/application/dialog/ApplicationStompDialog.vue' /* webpackChunkName: "components/application-stomp-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationUserGuidesDialog = () => import('../../components/application/dialog/ApplicationUserGuidesDialog.vue' /* webpackChunkName: "components/application-user-guides-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationXeroDialog = () => import('../../components/application/dialog/ApplicationXeroDialog.vue' /* webpackChunkName: "components/application-xero-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationContextSearchInput = () => import('../../components/application/input/ApplicationContextSearchInput.vue' /* webpackChunkName: "components/application-context-search-input" */).then(c => wrapFunctional(c.default || c))
export const ApplicationIconPicker = () => import('../../components/application/input/ApplicationIconPicker.vue' /* webpackChunkName: "components/application-icon-picker" */).then(c => wrapFunctional(c.default || c))
export const ApplicationOwnershipInput = () => import('../../components/application/input/ApplicationOwnershipInput.vue' /* webpackChunkName: "components/application-ownership-input" */).then(c => wrapFunctional(c.default || c))
export const ApplicationRichTextEditor = () => import('../../components/application/input/ApplicationRichTextEditor.vue' /* webpackChunkName: "components/application-rich-text-editor" */).then(c => wrapFunctional(c.default || c))
export const ApplicationRichTextInput = () => import('../../components/application/input/ApplicationRichTextInput.vue' /* webpackChunkName: "components/application-rich-text-input" */).then(c => wrapFunctional(c.default || c))
export const ApplicationRichTextLabel = () => import('../../components/application/input/ApplicationRichTextLabel.vue' /* webpackChunkName: "components/application-rich-text-label" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTableInput = () => import('../../components/application/input/ApplicationTableInput.vue' /* webpackChunkName: "components/application-table-input" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTextEditorInput = () => import('../../components/application/input/ApplicationTextEditorInput.vue' /* webpackChunkName: "components/application-text-editor-input" */).then(c => wrapFunctional(c.default || c))
export const ApplicationHeatMapLabel = () => import('../../components/application/label/ApplicationHeatMapLabel.vue' /* webpackChunkName: "components/application-heat-map-label" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTableLabel = () => import('../../components/application/label/ApplicationTableLabel.vue' /* webpackChunkName: "components/application-table-label" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTruncateLabel = () => import('../../components/application/label/ApplicationTruncateLabel.vue' /* webpackChunkName: "components/application-truncate-label" */).then(c => wrapFunctional(c.default || c))
export const ApplicationAccountMenu = () => import('../../components/application/menu/ApplicationAccountMenu.vue' /* webpackChunkName: "components/application-account-menu" */).then(c => wrapFunctional(c.default || c))
export const ApplicationContextMenu = () => import('../../components/application/menu/ApplicationContextMenu.vue' /* webpackChunkName: "components/application-context-menu" */).then(c => wrapFunctional(c.default || c))
export const ApplicationFilterMenu = () => import('../../components/application/menu/ApplicationFilterMenu.vue' /* webpackChunkName: "components/application-filter-menu" */).then(c => wrapFunctional(c.default || c))
export const ApplicationKnowledgeBaseMenu = () => import('../../components/application/menu/ApplicationKnowledgeBaseMenu.vue' /* webpackChunkName: "components/application-knowledge-base-menu" */).then(c => wrapFunctional(c.default || c))
export const ApplicationNotificationMenu = () => import('../../components/application/menu/ApplicationNotificationMenu.vue' /* webpackChunkName: "components/application-notification-menu" */).then(c => wrapFunctional(c.default || c))
export const ApplicationPrivacyMenu = () => import('../../components/application/menu/ApplicationPrivacyMenu.vue' /* webpackChunkName: "components/application-privacy-menu" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSimpleFilter = () => import('../../components/application/menu/ApplicationSimpleFilter.vue' /* webpackChunkName: "components/application-simple-filter" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTaskRepeatMenu = () => import('../../components/application/menu/ApplicationTaskRepeatMenu.vue' /* webpackChunkName: "components/application-task-repeat-menu" */).then(c => wrapFunctional(c.default || c))
export const ApplicationActionTable = () => import('../../components/application/table/ApplicationActionTable.vue' /* webpackChunkName: "components/application-action-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicationChecklistTable = () => import('../../components/application/table/ApplicationChecklistTable.vue' /* webpackChunkName: "components/application-checklist-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDynamicVirtualScroll = () => import('../../components/application/table/ApplicationDynamicVirtualScroll.vue' /* webpackChunkName: "components/application-dynamic-virtual-scroll" */).then(c => wrapFunctional(c.default || c))
export const ApplicationMultiEditTable = () => import('../../components/application/table/ApplicationMultiEditTable.vue' /* webpackChunkName: "components/application-multi-edit-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicationMultiEditTableNew = () => import('../../components/application/table/ApplicationMultiEditTableNew.vue' /* webpackChunkName: "components/application-multi-edit-table-new" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSimpleTable = () => import('../../components/application/table/ApplicationSimpleTable.vue' /* webpackChunkName: "components/application-simple-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTable = () => import('../../components/application/table/ApplicationTable.vue' /* webpackChunkName: "components/application-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicationVirtualTable = () => import('../../components/application/table/ApplicationVirtualTable.vue' /* webpackChunkName: "components/application-virtual-table" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCustomSearchDialog = () => import('../../components/application/search/ApplicationCustomSearchDialog.vue' /* webpackChunkName: "components/application-custom-search-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSearchDialog = () => import('../../components/application/search/ApplicationSearchDialog.vue' /* webpackChunkName: "components/application-search-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSearchLabel = () => import('../../components/application/search/ApplicationSearchLabel.vue' /* webpackChunkName: "components/application-search-label" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSearchListItem = () => import('../../components/application/search/ApplicationSearchListItem.vue' /* webpackChunkName: "components/application-search-list-item" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSearchMenuItem = () => import('../../components/application/search/ApplicationSearchMenuItem.vue' /* webpackChunkName: "components/application-search-menu-item" */).then(c => wrapFunctional(c.default || c))
export const ElementBuilderDialog = () => import('../../components/documentEngine/elements/ElementBuilderDialog.vue' /* webpackChunkName: "components/element-builder-dialog" */).then(c => wrapFunctional(c.default || c))
export const ElementDialog = () => import('../../components/documentEngine/elements/ElementDialog.vue' /* webpackChunkName: "components/element-dialog" */).then(c => wrapFunctional(c.default || c))
export const ElementMappingDialog = () => import('../../components/documentEngine/elements/ElementMappingDialog.vue' /* webpackChunkName: "components/element-mapping-dialog" */).then(c => wrapFunctional(c.default || c))
export const ElementsView = () => import('../../components/documentEngine/elements/ElementsView.vue' /* webpackChunkName: "components/elements-view" */).then(c => wrapFunctional(c.default || c))
export const SnippetMappingDialog = () => import('../../components/documentEngine/snippets/SnippetMappingDialog.vue' /* webpackChunkName: "components/snippet-mapping-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationAppSelect = () => import('../../components/application/ApplicationAppSelect.vue' /* webpackChunkName: "components/application-app-select" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCenterSnack = () => import('../../components/application/ApplicationCenterSnack.vue' /* webpackChunkName: "components/application-center-snack" */).then(c => wrapFunctional(c.default || c))
export const ApplicationDraggableList = () => import('../../components/application/ApplicationDraggableList.vue' /* webpackChunkName: "components/application-draggable-list" */).then(c => wrapFunctional(c.default || c))
export const ApplicationForceSeatManagement = () => import('../../components/application/ApplicationForceSeatManagement.vue' /* webpackChunkName: "components/application-force-seat-management" */).then(c => wrapFunctional(c.default || c))
export const ApplicationHeaderBar = () => import('../../components/application/ApplicationHeaderBar.vue' /* webpackChunkName: "components/application-header-bar" */).then(c => wrapFunctional(c.default || c))
export const ApplicationLoader = () => import('../../components/application/ApplicationLoader.vue' /* webpackChunkName: "components/application-loader" */).then(c => wrapFunctional(c.default || c))
export const ApplicationModuleShell = () => import('../../components/application/ApplicationModuleShell.vue' /* webpackChunkName: "components/application-module-shell" */).then(c => wrapFunctional(c.default || c))
export const ApplicationModuleSidebar = () => import('../../components/application/ApplicationModuleSidebar.vue' /* webpackChunkName: "components/application-module-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ApplicationPanzoom = () => import('../../components/application/ApplicationPanzoom.vue' /* webpackChunkName: "components/application-panzoom" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSelectEntityGroup = () => import('../../components/application/ApplicationSelectEntityGroup.vue' /* webpackChunkName: "components/application-select-entity-group" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSelectModule = () => import('../../components/application/ApplicationSelectModule.vue' /* webpackChunkName: "components/application-select-module" */).then(c => wrapFunctional(c.default || c))
export const ApplicationServerStatusIndicator = () => import('../../components/application/ApplicationServerStatusIndicator.vue' /* webpackChunkName: "components/application-server-status-indicator" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSidebar = () => import('../../components/application/ApplicationSidebar.vue' /* webpackChunkName: "components/application-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSidebarListItem = () => import('../../components/application/ApplicationSidebarListItem.vue' /* webpackChunkName: "components/application-sidebar-list-item" */).then(c => wrapFunctional(c.default || c))
export const ApplicationSnack = () => import('../../components/application/ApplicationSnack.vue' /* webpackChunkName: "components/application-snack" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTabs = () => import('../../components/application/ApplicationTabs.vue' /* webpackChunkName: "components/application-tabs" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTopBar = () => import('../../components/application/ApplicationTopBar.vue' /* webpackChunkName: "components/application-top-bar" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTrialExpiredDialog = () => import('../../components/application/ApplicationTrialExpiredDialog.vue' /* webpackChunkName: "components/application-trial-expired-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationUpgradeDialog = () => import('../../components/application/ApplicationUpgradeDialog.vue' /* webpackChunkName: "components/application-upgrade-dialog" */).then(c => wrapFunctional(c.default || c))
export const ApplicationUserLogoutModal = () => import('../../components/application/ApplicationUserLogoutModal.vue' /* webpackChunkName: "components/application-user-logout-modal" */).then(c => wrapFunctional(c.default || c))
export const DiffViewer = () => import('../../components/application/DiffViewer.vue' /* webpackChunkName: "components/diff-viewer" */).then(c => wrapFunctional(c.default || c))
export const SingleEdit = () => import('../../components/application/SingleEdit.vue' /* webpackChunkName: "components/single-edit" */).then(c => wrapFunctional(c.default || c))
export const DashboardActiveRequestsCard = () => import('../../components/application/dashboard/ActiveRequestsCard.vue' /* webpackChunkName: "components/dashboard-active-requests-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardActiveTasksCard = () => import('../../components/application/dashboard/ActiveTasksCard.vue' /* webpackChunkName: "components/dashboard-active-tasks-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardActiveWorkflowsCard = () => import('../../components/application/dashboard/ActiveWorkflowsCard.vue' /* webpackChunkName: "components/dashboard-active-workflows-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardAddressManagerCard = () => import('../../components/application/dashboard/AddressManagerCard.vue' /* webpackChunkName: "components/dashboard-address-manager-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardDaysEventsCard = () => import('../../components/application/dashboard/DaysEventsCard.vue' /* webpackChunkName: "components/dashboard-days-events-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardEntitiesCard = () => import('../../components/application/dashboard/EntitiesCard.vue' /* webpackChunkName: "components/dashboard-entities-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRecentClientGroupsCard = () => import('../../components/application/dashboard/RecentClientGroupsCard.vue' /* webpackChunkName: "components/dashboard-recent-client-groups-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardTimelineCard = () => import('../../components/application/dashboard/TimelineCard.vue' /* webpackChunkName: "components/dashboard-timeline-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardTodayEventsCard = () => import('../../components/application/dashboard/TodayEventsCard.vue' /* webpackChunkName: "components/dashboard-today-events-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardWeekEventsCard = () => import('../../components/application/dashboard/WeekEventsCard.vue' /* webpackChunkName: "components/dashboard-week-events-card" */).then(c => wrapFunctional(c.default || c))
export const Beneficiaries = () => import('../../components/common/Beneficiaries.vue' /* webpackChunkName: "components/beneficiaries" */).then(c => wrapFunctional(c.default || c))
export const Holdings = () => import('../../components/common/Holdings.vue' /* webpackChunkName: "components/holdings" */).then(c => wrapFunctional(c.default || c))
export const OngoingFees = () => import('../../components/common/OngoingFees.vue' /* webpackChunkName: "components/ongoing-fees" */).then(c => wrapFunctional(c.default || c))
export const TransactionFees = () => import('../../components/common/TransactionFees.vue' /* webpackChunkName: "components/transaction-fees" */).then(c => wrapFunctional(c.default || c))
export const ActivitiesActivityDialog = () => import('../../components/crm/activities/ActivityDialog.vue' /* webpackChunkName: "components/activities-activity-dialog" */).then(c => wrapFunctional(c.default || c))
export const ComplianceAdviceFeeConsentCard = () => import('../../components/crm/compliance/ComplianceAdviceFeeConsentCard.vue' /* webpackChunkName: "components/compliance-advice-fee-consent-card" */).then(c => wrapFunctional(c.default || c))
export const ComplianceServicePackageCard = () => import('../../components/crm/compliance/ComplianceServicePackageCard.vue' /* webpackChunkName: "components/compliance-service-package-card" */).then(c => wrapFunctional(c.default || c))
export const ComplianceSummaryCard = () => import('../../components/crm/compliance/ComplianceSummaryCard.vue' /* webpackChunkName: "components/compliance-summary-card" */).then(c => wrapFunctional(c.default || c))
export const ComplianceView = () => import('../../components/crm/compliance/ComplianceView.vue' /* webpackChunkName: "components/compliance-view" */).then(c => wrapFunctional(c.default || c))
export const DocumentLibraryCard = () => import('../../components/crm/documentLibrary/DocumentLibraryCard.vue' /* webpackChunkName: "components/document-library-card" */).then(c => wrapFunctional(c.default || c))
export const DocumentLibraryContextMenu = () => import('../../components/crm/documentLibrary/DocumentLibraryContextMenu.vue' /* webpackChunkName: "components/document-library-context-menu" */).then(c => wrapFunctional(c.default || c))
export const DocumentLibraryFilePreviewIframe = () => import('../../components/crm/documentLibrary/DocumentLibraryFilePreviewIframe.vue' /* webpackChunkName: "components/document-library-file-preview-iframe" */).then(c => wrapFunctional(c.default || c))
export const DocumentLibraryInfoCard = () => import('../../components/crm/documentLibrary/DocumentLibraryInfoCard.vue' /* webpackChunkName: "components/document-library-info-card" */).then(c => wrapFunctional(c.default || c))
export const DocumentLibraryTreeView = () => import('../../components/crm/documentLibrary/DocumentLibraryTreeView.vue' /* webpackChunkName: "components/document-library-tree-view" */).then(c => wrapFunctional(c.default || c))
export const DocumentLibraryView = () => import('../../components/crm/documentLibrary/DocumentLibraryView.vue' /* webpackChunkName: "components/document-library-view" */).then(c => wrapFunctional(c.default || c))
export const EntitiesAmlCtfCard = () => import('../../components/crm/entities/AmlCtfCard.vue' /* webpackChunkName: "components/entities-aml-ctf-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesContactInformationCard = () => import('../../components/crm/entities/ContactInformationCard.vue' /* webpackChunkName: "components/entities-contact-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesRolesAndRelationshipsCard = () => import('../../components/crm/entities/RolesAndRelationshipsCard.vue' /* webpackChunkName: "components/entities-roles-and-relationships-card" */).then(c => wrapFunctional(c.default || c))
export const EntityGroupsAddressManagerCard = () => import('../../components/crm/entityGroups/AddressManagerCard.vue' /* webpackChunkName: "components/entity-groups-address-manager-card" */).then(c => wrapFunctional(c.default || c))
export const EntityGroupsAdviceRegisterCard = () => import('../../components/crm/entityGroups/AdviceRegisterCard.vue' /* webpackChunkName: "components/entity-groups-advice-register-card" */).then(c => wrapFunctional(c.default || c))
export const EntityGroupsAdviceTeamCard = () => import('../../components/crm/entityGroups/AdviceTeamCard.vue' /* webpackChunkName: "components/entity-groups-advice-team-card" */).then(c => wrapFunctional(c.default || c))
export const EntityGroupsExternalProfessionalsCard = () => import('../../components/crm/entityGroups/ExternalProfessionalsCard.vue' /* webpackChunkName: "components/entity-groups-external-professionals-card" */).then(c => wrapFunctional(c.default || c))
export const EntityGroupsFsgCard = () => import('../../components/crm/entityGroups/FsgCard.vue' /* webpackChunkName: "components/entity-groups-fsg-card" */).then(c => wrapFunctional(c.default || c))
export const EntityGroupsMarketingInformationCard = () => import('../../components/crm/entityGroups/MarketingInformationCard.vue' /* webpackChunkName: "components/entity-groups-marketing-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntityGroupsServiceAgreementsCard = () => import('../../components/crm/entityGroups/ServiceAgreementsCard.vue' /* webpackChunkName: "components/entity-groups-service-agreements-card" */).then(c => wrapFunctional(c.default || c))
export const EntityGroupsSummaryCard = () => import('../../components/crm/entityGroups/SummaryCard.vue' /* webpackChunkName: "components/entity-groups-summary-card" */).then(c => wrapFunctional(c.default || c))
export const FactFindCard = () => import('../../components/crm/factFind/FactFindCard.vue' /* webpackChunkName: "components/fact-find-card" */).then(c => wrapFunctional(c.default || c))
export const FactFindView = () => import('../../components/crm/factFind/FactFindView.vue' /* webpackChunkName: "components/fact-find-view" */).then(c => wrapFunctional(c.default || c))
export const FileNoteSnippetsDialog = () => import('../../components/crm/fileNotes/FileNoteSnippetsDialog.vue' /* webpackChunkName: "components/file-note-snippets-dialog" */).then(c => wrapFunctional(c.default || c))
export const GoalsEventsMilestonesClientGoalsCard = () => import('../../components/crm/goalsEventsMilestones/ClientGoalsCard.vue' /* webpackChunkName: "components/goals-events-milestones-client-goals-card" */).then(c => wrapFunctional(c.default || c))
export const GoalsEventsMilestonesReasonsCard = () => import('../../components/crm/goalsEventsMilestones/ReasonsCard.vue' /* webpackChunkName: "components/goals-events-milestones-reasons-card" */).then(c => wrapFunctional(c.default || c))
export const GovernmentBenefitsAllowancesCard = () => import('../../components/crm/governmentBenefits/AllowancesCard.vue' /* webpackChunkName: "components/government-benefits-allowances-card" */).then(c => wrapFunctional(c.default || c))
export const GovernmentBenefitsConcessionHealthCardsCard = () => import('../../components/crm/governmentBenefits/ConcessionHealthCardsCard.vue' /* webpackChunkName: "components/government-benefits-concession-health-cards-card" */).then(c => wrapFunctional(c.default || c))
export const GovernmentBenefitsFamilyBenefitsCard = () => import('../../components/crm/governmentBenefits/FamilyBenefitsCard.vue' /* webpackChunkName: "components/government-benefits-family-benefits-card" */).then(c => wrapFunctional(c.default || c))
export const GovernmentBenefitsPensionsCard = () => import('../../components/crm/governmentBenefits/PensionsCard.vue' /* webpackChunkName: "components/government-benefits-pensions-card" */).then(c => wrapFunctional(c.default || c))
export const HealthSummaryCard = () => import('../../components/crm/health/HealthSummaryCard.vue' /* webpackChunkName: "components/health-summary-card" */).then(c => wrapFunctional(c.default || c))
export const HealthView = () => import('../../components/crm/health/HealthView.vue' /* webpackChunkName: "components/health-view" */).then(c => wrapFunctional(c.default || c))
export const IncomeExpensesExpenseCard = () => import('../../components/crm/incomeExpenses/ExpenseCard.vue' /* webpackChunkName: "components/income-expenses-expense-card" */).then(c => wrapFunctional(c.default || c))
export const IncomeExpenseCashFlowDialog = () => import('../../components/crm/incomeExpenses/IncomeExpenseCashFlowDialog.vue' /* webpackChunkName: "components/income-expense-cash-flow-dialog" */).then(c => wrapFunctional(c.default || c))
export const IncomeExpenseTaxDialog = () => import('../../components/crm/incomeExpenses/IncomeExpenseTaxDialog.vue' /* webpackChunkName: "components/income-expense-tax-dialog" */).then(c => wrapFunctional(c.default || c))
export const IncomeExpensesIncomesCard = () => import('../../components/crm/incomeExpenses/IncomesCard.vue' /* webpackChunkName: "components/income-expenses-incomes-card" */).then(c => wrapFunctional(c.default || c))
export const InsurancesBeneficiariesCard = () => import('../../components/crm/insurances/BeneficiariesCard.vue' /* webpackChunkName: "components/insurances-beneficiaries-card" */).then(c => wrapFunctional(c.default || c))
export const InsurancesCommissionCard = () => import('../../components/crm/insurances/CommissionCard.vue' /* webpackChunkName: "components/insurances-commission-card" */).then(c => wrapFunctional(c.default || c))
export const InsurancesCoverCard = () => import('../../components/crm/insurances/CoverCard.vue' /* webpackChunkName: "components/insurances-cover-card" */).then(c => wrapFunctional(c.default || c))
export const InsurancesSummaryCard = () => import('../../components/crm/insurances/SummaryCard.vue' /* webpackChunkName: "components/insurances-summary-card" */).then(c => wrapFunctional(c.default || c))
export const InsurancesUnderwritingCard = () => import('../../components/crm/insurances/UnderwritingCard.vue' /* webpackChunkName: "components/insurances-underwriting-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsBalanceCard = () => import('../../components/crm/superannuations/BalanceCard.vue' /* webpackChunkName: "components/superannuations-balance-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsBeneficiariesCard = () => import('../../components/crm/superannuations/BeneficiariesCard.vue' /* webpackChunkName: "components/superannuations-beneficiaries-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsDetailedInformationCard = () => import('../../components/crm/superannuations/DetailedInformationCard.vue' /* webpackChunkName: "components/superannuations-detailed-information-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsHoldingsCard = () => import('../../components/crm/superannuations/HoldingsCard.vue' /* webpackChunkName: "components/superannuations-holdings-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsOngoingFeesCard = () => import('../../components/crm/superannuations/OngoingFeesCard.vue' /* webpackChunkName: "components/superannuations-ongoing-fees-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsSummaryCard = () => import('../../components/crm/superannuations/SummaryCard.vue' /* webpackChunkName: "components/superannuations-summary-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsTransactionFeesCard = () => import('../../components/crm/superannuations/TransactionFeesCard.vue' /* webpackChunkName: "components/superannuations-transaction-fees-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsAnnuityAnnuitysCard = () => import('../../components/crm/assets/annuity/AnnuitysCard.vue' /* webpackChunkName: "components/assets-annuity-annuitys-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsAnnuityDetailedInformationCard = () => import('../../components/crm/assets/annuity/DetailedInformationCard.vue' /* webpackChunkName: "components/assets-annuity-detailed-information-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsAnnuitySummaryCard = () => import('../../components/crm/assets/annuity/SummaryCard.vue' /* webpackChunkName: "components/assets-annuity-summary-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsCashaccountAccountDetailsCard = () => import('../../components/crm/assets/cashaccount/AccountDetailsCard.vue' /* webpackChunkName: "components/assets-cashaccount-account-details-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsCashaccountBalanceCard = () => import('../../components/crm/assets/cashaccount/BalanceCard.vue' /* webpackChunkName: "components/assets-cashaccount-balance-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsCashaccountSummaryCard = () => import('../../components/crm/assets/cashaccount/SummaryCard.vue' /* webpackChunkName: "components/assets-cashaccount-summary-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsInvestmentaccountBalanceCard = () => import('../../components/crm/assets/investmentaccount/BalanceCard.vue' /* webpackChunkName: "components/assets-investmentaccount-balance-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsInvestmentaccountDetailedInformationCard = () => import('../../components/crm/assets/investmentaccount/DetailedInformationCard.vue' /* webpackChunkName: "components/assets-investmentaccount-detailed-information-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsInvestmentaccountFeesCard = () => import('../../components/crm/assets/investmentaccount/FeesCard.vue' /* webpackChunkName: "components/assets-investmentaccount-fees-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsInvestmentaccountHoldingsCard = () => import('../../components/crm/assets/investmentaccount/HoldingsCard.vue' /* webpackChunkName: "components/assets-investmentaccount-holdings-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsInvestmentaccountSummaryCard = () => import('../../components/crm/assets/investmentaccount/SummaryCard.vue' /* webpackChunkName: "components/assets-investmentaccount-summary-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsInvestmentaccountTransactionFeesCard = () => import('../../components/crm/assets/investmentaccount/TransactionFeesCard.vue' /* webpackChunkName: "components/assets-investmentaccount-transaction-fees-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsLifestyleassetsSummaryCard = () => import('../../components/crm/assets/lifestyleassets/SummaryCard.vue' /* webpackChunkName: "components/assets-lifestyleassets-summary-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsPropertyCapitalCard = () => import('../../components/crm/assets/property/CapitalCard.vue' /* webpackChunkName: "components/assets-property-capital-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsPropertyIncomeAndExpensesCard = () => import('../../components/crm/assets/property/IncomeAndExpensesCard.vue' /* webpackChunkName: "components/assets-property-income-and-expenses-card" */).then(c => wrapFunctional(c.default || c))
export const AssetsPropertySummaryCard = () => import('../../components/crm/assets/property/SummaryCard.vue' /* webpackChunkName: "components/assets-property-summary-card" */).then(c => wrapFunctional(c.default || c))
export const EmploymentEmployeeDetailsCard = () => import('../../components/crm/employment/employee/DetailsCard.vue' /* webpackChunkName: "components/employment-employee-details-card" */).then(c => wrapFunctional(c.default || c))
export const EmploymentEmployeeIncomesCard = () => import('../../components/crm/employment/employee/IncomesCard.vue' /* webpackChunkName: "components/employment-employee-incomes-card" */).then(c => wrapFunctional(c.default || c))
export const EmploymentEmployeeSalaryPackagingCard = () => import('../../components/crm/employment/employee/SalaryPackagingCard.vue' /* webpackChunkName: "components/employment-employee-salary-packaging-card" */).then(c => wrapFunctional(c.default || c))
export const EmploymentEmployeeSummaryCard = () => import('../../components/crm/employment/employee/SummaryCard.vue' /* webpackChunkName: "components/employment-employee-summary-card" */).then(c => wrapFunctional(c.default || c))
export const EmploymentEmployeeSuperannuationCard = () => import('../../components/crm/employment/employee/SuperannuationCard.vue' /* webpackChunkName: "components/employment-employee-superannuation-card" */).then(c => wrapFunctional(c.default || c))
export const EmploymentEmployeeTaxInformationCard = () => import('../../components/crm/employment/employee/TaxInformationCard.vue' /* webpackChunkName: "components/employment-employee-tax-information-card" */).then(c => wrapFunctional(c.default || c))
export const EmploymentNonworkingSummaryCard = () => import('../../components/crm/employment/nonworking/SummaryCard.vue' /* webpackChunkName: "components/employment-nonworking-summary-card" */).then(c => wrapFunctional(c.default || c))
export const EmploymentSelfemployedExpensesCard = () => import('../../components/crm/employment/selfemployed/ExpensesCard.vue' /* webpackChunkName: "components/employment-selfemployed-expenses-card" */).then(c => wrapFunctional(c.default || c))
export const EmploymentSelfemployedIncomeAndExpenseCard = () => import('../../components/crm/employment/selfemployed/IncomeAndExpenseCard.vue' /* webpackChunkName: "components/employment-selfemployed-income-and-expense-card" */).then(c => wrapFunctional(c.default || c))
export const EmploymentSelfemployedSummaryCard = () => import('../../components/crm/employment/selfemployed/SummaryCard.vue' /* webpackChunkName: "components/employment-selfemployed-summary-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesCompanyContributionsCard = () => import('../../components/crm/entities/company/ContributionsCard.vue' /* webpackChunkName: "components/entities-company-contributions-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesCompanyDetailsCard = () => import('../../components/crm/entities/company/DetailsCard.vue' /* webpackChunkName: "components/entities-company-details-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesCompanyGovernmentInformationCard = () => import('../../components/crm/entities/company/GovernmentInformationCard.vue' /* webpackChunkName: "components/entities-company-government-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesCompanyShareholdersCard = () => import('../../components/crm/entities/company/ShareholdersCard.vue' /* webpackChunkName: "components/entities-company-shareholders-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesCompanySummaryCard = () => import('../../components/crm/entities/company/SummaryCard.vue' /* webpackChunkName: "components/entities-company-summary-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesCompanyTaxInformationCard = () => import('../../components/crm/entities/company/TaxInformationCard.vue' /* webpackChunkName: "components/entities-company-tax-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesIndividualAmlCtfCard = () => import('../../components/crm/entities/individual/AmlCtfCard.vue' /* webpackChunkName: "components/entities-individual-aml-ctf-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesIndividualContactInformationCard = () => import('../../components/crm/entities/individual/ContactInformationCard.vue' /* webpackChunkName: "components/entities-individual-contact-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesIndividualDetailsCard = () => import('../../components/crm/entities/individual/DetailsCard.vue' /* webpackChunkName: "components/entities-individual-details-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesIndividualEducationCard = () => import('../../components/crm/entities/individual/EducationCard.vue' /* webpackChunkName: "components/entities-individual-education-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesIndividualEstatePlanningCard = () => import('../../components/crm/entities/individual/EstatePlanningCard.vue' /* webpackChunkName: "components/entities-individual-estate-planning-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesIndividualGovernmentInformationCard = () => import('../../components/crm/entities/individual/GovernmentInformationCard.vue' /* webpackChunkName: "components/entities-individual-government-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesIndividualHealthInformationCard = () => import('../../components/crm/entities/individual/HealthInformationCard.vue' /* webpackChunkName: "components/entities-individual-health-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesIndividualRolesAndRelationshipsCard = () => import('../../components/crm/entities/individual/RolesAndRelationshipsCard.vue' /* webpackChunkName: "components/entities-individual-roles-and-relationships-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesIndividualSummaryCard = () => import('../../components/crm/entities/individual/SummaryCard.vue' /* webpackChunkName: "components/entities-individual-summary-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesIndividualTaxInformationCard = () => import('../../components/crm/entities/individual/TaxInformationCard.vue' /* webpackChunkName: "components/entities-individual-tax-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesPartnershipContributionsCard = () => import('../../components/crm/entities/partnership/ContributionsCard.vue' /* webpackChunkName: "components/entities-partnership-contributions-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesPartnershipDetailsCard = () => import('../../components/crm/entities/partnership/DetailsCard.vue' /* webpackChunkName: "components/entities-partnership-details-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesPartnershipGovernmentInformationCard = () => import('../../components/crm/entities/partnership/GovernmentInformationCard.vue' /* webpackChunkName: "components/entities-partnership-government-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesPartnershipPartnershipsCard = () => import('../../components/crm/entities/partnership/PartnershipsCard.vue' /* webpackChunkName: "components/entities-partnership-partnerships-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesPartnershipSummaryCard = () => import('../../components/crm/entities/partnership/SummaryCard.vue' /* webpackChunkName: "components/entities-partnership-summary-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesPartnershipTaxInformationCard = () => import('../../components/crm/entities/partnership/TaxInformationCard.vue' /* webpackChunkName: "components/entities-partnership-tax-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesSmsfDetailsCard = () => import('../../components/crm/entities/smsf/DetailsCard.vue' /* webpackChunkName: "components/entities-smsf-details-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesSmsfGovernmentInformationCard = () => import('../../components/crm/entities/smsf/GovernmentInformationCard.vue' /* webpackChunkName: "components/entities-smsf-government-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesSmsfMemberBalancesCard = () => import('../../components/crm/entities/smsf/MemberBalancesCard.vue' /* webpackChunkName: "components/entities-smsf-member-balances-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesSmsfSummaryCard = () => import('../../components/crm/entities/smsf/SummaryCard.vue' /* webpackChunkName: "components/entities-smsf-summary-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesSmsfTaxInformationCard = () => import('../../components/crm/entities/smsf/TaxInformationCard.vue' /* webpackChunkName: "components/entities-smsf-tax-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesTrustBeneficiariesCard = () => import('../../components/crm/entities/trust/BeneficiariesCard.vue' /* webpackChunkName: "components/entities-trust-beneficiaries-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesTrustDetailsCard = () => import('../../components/crm/entities/trust/DetailsCard.vue' /* webpackChunkName: "components/entities-trust-details-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesTrustGovernmentInformationCard = () => import('../../components/crm/entities/trust/GovernmentInformationCard.vue' /* webpackChunkName: "components/entities-trust-government-information-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesTrustSummaryCard = () => import('../../components/crm/entities/trust/SummaryCard.vue' /* webpackChunkName: "components/entities-trust-summary-card" */).then(c => wrapFunctional(c.default || c))
export const EntitiesTrustTaxInformationCard = () => import('../../components/crm/entities/trust/TaxInformationCard.vue' /* webpackChunkName: "components/entities-trust-tax-information-card" */).then(c => wrapFunctional(c.default || c))
export const IncomeExpenses = () => import('../../components/crm/incomeExpenses/mixins/incomeExpenses.js' /* webpackChunkName: "components/income-expenses" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesLoanAdvancedInformationCard = () => import('../../components/crm/liabilities/loan/AdvancedInformationCard.vue' /* webpackChunkName: "components/liabilities-loan-advanced-information-card" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesLoanBalanceCard = () => import('../../components/crm/liabilities/loan/BalanceCard.vue' /* webpackChunkName: "components/liabilities-loan-balance-card" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesLoanDetailedInformationCard = () => import('../../components/crm/liabilities/loan/DetailedInformationCard.vue' /* webpackChunkName: "components/liabilities-loan-detailed-information-card" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesLoanLoansRepaymentsCard = () => import('../../components/crm/liabilities/loan/LoansRepaymentsCard.vue' /* webpackChunkName: "components/liabilities-loan-loans-repayments-card" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesLoanRedrawCard = () => import('../../components/crm/liabilities/loan/RedrawCard.vue' /* webpackChunkName: "components/liabilities-loan-redraw-card" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesLoanSummaryCard = () => import('../../components/crm/liabilities/loan/SummaryCard.vue' /* webpackChunkName: "components/liabilities-loan-summary-card" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesStudentloanAdvancedInformationCard = () => import('../../components/crm/liabilities/studentloan/AdvancedInformationCard.vue' /* webpackChunkName: "components/liabilities-studentloan-advanced-information-card" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesStudentloanBalanceCard = () => import('../../components/crm/liabilities/studentloan/BalanceCard.vue' /* webpackChunkName: "components/liabilities-studentloan-balance-card" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesStudentloanLoansRepaymentsCard = () => import('../../components/crm/liabilities/studentloan/LoansRepaymentsCard.vue' /* webpackChunkName: "components/liabilities-studentloan-loans-repayments-card" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesStudentloanRedrawCard = () => import('../../components/crm/liabilities/studentloan/RedrawCard.vue' /* webpackChunkName: "components/liabilities-studentloan-redraw-card" */).then(c => wrapFunctional(c.default || c))
export const LiabilitiesStudentloanSummaryCard = () => import('../../components/crm/liabilities/studentloan/SummaryCard.vue' /* webpackChunkName: "components/liabilities-studentloan-summary-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsAccountbasedpensionPensionInformationCard = () => import('../../components/crm/superannuations/accountbasedpension/PensionInformationCard.vue' /* webpackChunkName: "components/superannuations-accountbasedpension-pension-information-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsAccumulationContributionHistoryCard = () => import('../../components/crm/superannuations/accumulation/ContributionHistoryCard.vue' /* webpackChunkName: "components/superannuations-accumulation-contribution-history-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsAccumulationOngoingContributionsCard = () => import('../../components/crm/superannuations/accumulation/OngoingContributionsCard.vue' /* webpackChunkName: "components/superannuations-accumulation-ongoing-contributions-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsDefinedbenefitBalanceCard = () => import('../../components/crm/superannuations/definedbenefit/BalanceCard.vue' /* webpackChunkName: "components/superannuations-definedbenefit-balance-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsDefinedbenefitBenefitInformationCard = () => import('../../components/crm/superannuations/definedbenefit/BenefitInformationCard.vue' /* webpackChunkName: "components/superannuations-definedbenefit-benefit-information-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsDefinedpensionPensionInformationCard = () => import('../../components/crm/superannuations/definedpension/PensionInformationCard.vue' /* webpackChunkName: "components/superannuations-definedpension-pension-information-card" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsSharedComponentsInputs = () => import('../../components/crm/superannuations/shared/ComponentsInputs.vue' /* webpackChunkName: "components/superannuations-shared-components-inputs" */).then(c => wrapFunctional(c.default || c))
export const SuperannuationsSuperannuityAnnuityInformationCard = () => import('../../components/crm/superannuations/superannuity/AnnuityInformationCard.vue' /* webpackChunkName: "components/superannuations-superannuity-annuity-information-card" */).then(c => wrapFunctional(c.default || c))
export const ConfigurationHubDashboardCard = () => import('../../components/configurationHub/ConfigurationHubDashboardCard.vue' /* webpackChunkName: "components/configuration-hub-dashboard-card" */).then(c => wrapFunctional(c.default || c))
export const ClientPortalSettingsTheme = () => import('../../components/configurationHub/clientPortalSettings/Theme.vue' /* webpackChunkName: "components/client-portal-settings-theme" */).then(c => wrapFunctional(c.default || c))
export const ClientPortalSettingsView = () => import('../../components/configurationHub/clientPortalSettings/View.vue' /* webpackChunkName: "components/client-portal-settings-view" */).then(c => wrapFunctional(c.default || c))
export const ClientSettingsSegmentation = () => import('../../components/configurationHub/clientSettings/Segmentation.vue' /* webpackChunkName: "components/client-settings-segmentation" */).then(c => wrapFunctional(c.default || c))
export const ClientSettingsView = () => import('../../components/configurationHub/clientSettings/View.vue' /* webpackChunkName: "components/client-settings-view" */).then(c => wrapFunctional(c.default || c))
export const DashboardSettingsTypes = () => import('../../components/configurationHub/dashboardSettings/Types.vue' /* webpackChunkName: "components/dashboard-settings-types" */).then(c => wrapFunctional(c.default || c))
export const DashboardSettingsView = () => import('../../components/configurationHub/dashboardSettings/View.vue' /* webpackChunkName: "components/dashboard-settings-view" */).then(c => wrapFunctional(c.default || c))
export const EntitySettingsProfiles = () => import('../../components/configurationHub/entitySettings/Profiles.vue' /* webpackChunkName: "components/entity-settings-profiles" */).then(c => wrapFunctional(c.default || c))
export const EntitySettingsView = () => import('../../components/configurationHub/entitySettings/View.vue' /* webpackChunkName: "components/entity-settings-view" */).then(c => wrapFunctional(c.default || c))
export const FileManagerSettingsDefaults = () => import('../../components/configurationHub/fileManagerSettings/Defaults.vue' /* webpackChunkName: "components/file-manager-settings-defaults" */).then(c => wrapFunctional(c.default || c))
export const FileManagerSettingsView = () => import('../../components/configurationHub/fileManagerSettings/View.vue' /* webpackChunkName: "components/file-manager-settings-view" */).then(c => wrapFunctional(c.default || c))
export const FileNoteSettingsTypes = () => import('../../components/configurationHub/fileNoteSettings/Types.vue' /* webpackChunkName: "components/file-note-settings-types" */).then(c => wrapFunctional(c.default || c))
export const FileNoteSettingsView = () => import('../../components/configurationHub/fileNoteSettings/View.vue' /* webpackChunkName: "components/file-note-settings-view" */).then(c => wrapFunctional(c.default || c))
export const GoalsSettingsCategory = () => import('../../components/configurationHub/goalsSettings/Category.vue' /* webpackChunkName: "components/goals-settings-category" */).then(c => wrapFunctional(c.default || c))
export const GoalsSettingsPriority = () => import('../../components/configurationHub/goalsSettings/Priority.vue' /* webpackChunkName: "components/goals-settings-priority" */).then(c => wrapFunctional(c.default || c))
export const GoalsSettingsStatus = () => import('../../components/configurationHub/goalsSettings/Status.vue' /* webpackChunkName: "components/goals-settings-status" */).then(c => wrapFunctional(c.default || c))
export const GoalsSettingsSubject = () => import('../../components/configurationHub/goalsSettings/Subject.vue' /* webpackChunkName: "components/goals-settings-subject" */).then(c => wrapFunctional(c.default || c))
export const GoalsSettingsTimeframe = () => import('../../components/configurationHub/goalsSettings/Timeframe.vue' /* webpackChunkName: "components/goals-settings-timeframe" */).then(c => wrapFunctional(c.default || c))
export const GoalsSettingsView = () => import('../../components/configurationHub/goalsSettings/View.vue' /* webpackChunkName: "components/goals-settings-view" */).then(c => wrapFunctional(c.default || c))
export const IntegrationSettingsGmail = () => import('../../components/configurationHub/integrationSettings/Gmail.vue' /* webpackChunkName: "components/integration-settings-gmail" */).then(c => wrapFunctional(c.default || c))
export const IntegrationSettingsView = () => import('../../components/configurationHub/integrationSettings/View.vue' /* webpackChunkName: "components/integration-settings-view" */).then(c => wrapFunctional(c.default || c))
export const IntegrationSettingsXero = () => import('../../components/configurationHub/integrationSettings/Xero.vue' /* webpackChunkName: "components/integration-settings-xero" */).then(c => wrapFunctional(c.default || c))
export const OrganisationSettingsBilling = () => import('../../components/configurationHub/organisationSettings/Billing.vue' /* webpackChunkName: "components/organisation-settings-billing" */).then(c => wrapFunctional(c.default || c))
export const OrganisationSettingsDetails = () => import('../../components/configurationHub/organisationSettings/Details.vue' /* webpackChunkName: "components/organisation-settings-details" */).then(c => wrapFunctional(c.default || c))
export const OrganisationSettingsIntegrations = () => import('../../components/configurationHub/organisationSettings/Integrations.vue' /* webpackChunkName: "components/organisation-settings-integrations" */).then(c => wrapFunctional(c.default || c))
export const OrganisationSettingsLayouts = () => import('../../components/configurationHub/organisationSettings/Layouts.vue' /* webpackChunkName: "components/organisation-settings-layouts" */).then(c => wrapFunctional(c.default || c))
export const OrganisationSettingsRoles = () => import('../../components/configurationHub/organisationSettings/Roles.vue' /* webpackChunkName: "components/organisation-settings-roles" */).then(c => wrapFunctional(c.default || c))
export const OrganisationSettingsTags = () => import('../../components/configurationHub/organisationSettings/Tags.vue' /* webpackChunkName: "components/organisation-settings-tags" */).then(c => wrapFunctional(c.default || c))
export const OrganisationSettingsTeams = () => import('../../components/configurationHub/organisationSettings/Teams.vue' /* webpackChunkName: "components/organisation-settings-teams" */).then(c => wrapFunctional(c.default || c))
export const OrganisationSettingsView = () => import('../../components/configurationHub/organisationSettings/View.vue' /* webpackChunkName: "components/organisation-settings-view" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalSettingsServices = () => import('../../components/configurationHub/professionalSettings/Services.vue' /* webpackChunkName: "components/professional-settings-services" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalSettingsView = () => import('../../components/configurationHub/professionalSettings/View.vue' /* webpackChunkName: "components/professional-settings-view" */).then(c => wrapFunctional(c.default || c))
export const UserSettingsDetails = () => import('../../components/configurationHub/userSettings/Details.vue' /* webpackChunkName: "components/user-settings-details" */).then(c => wrapFunctional(c.default || c))
export const UserSettingsView = () => import('../../components/configurationHub/userSettings/View.vue' /* webpackChunkName: "components/user-settings-view" */).then(c => wrapFunctional(c.default || c))
export const DataFeedView = () => import('../../components/practiceManager/dataFeedManager/DataFeedView.vue' /* webpackChunkName: "components/data-feed-view" */).then(c => wrapFunctional(c.default || c))
export const PipelineView = () => import('../../components/practiceManager/pipeline/PipelineView.vue' /* webpackChunkName: "components/pipeline-view" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalsClientsCard = () => import('../../components/practiceManager/professionals/ClientsCard.vue' /* webpackChunkName: "components/professionals-clients-card" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalsContactInformationCard = () => import('../../components/practiceManager/professionals/ContactInformationCard.vue' /* webpackChunkName: "components/professionals-contact-information-card" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalsDepartmentCard = () => import('../../components/practiceManager/professionals/DepartmentCard.vue' /* webpackChunkName: "components/professionals-department-card" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalsProfessionalCard = () => import('../../components/practiceManager/professionals/ProfessionalCard.vue' /* webpackChunkName: "components/professionals-professional-card" */).then(c => wrapFunctional(c.default || c))
export const ProfessionalsSummaryCard = () => import('../../components/practiceManager/professionals/SummaryCard.vue' /* webpackChunkName: "components/professionals-summary-card" */).then(c => wrapFunctional(c.default || c))
export const ProvidersSummaryCard = () => import('../../components/practiceManager/providers/ProvidersSummaryCard.vue' /* webpackChunkName: "components/providers-summary-card" */).then(c => wrapFunctional(c.default || c))
export const ProvidersView = () => import('../../components/practiceManager/providers/ProvidersView.vue' /* webpackChunkName: "components/providers-view" */).then(c => wrapFunctional(c.default || c))
export const AdviceTemplatesView = () => import('../../components/adviceBuilder/adviceTemplates/AdviceTemplatesView.vue' /* webpackChunkName: "components/advice-templates-view" */).then(c => wrapFunctional(c.default || c))
export const InsuranceResearchView = () => import('../../components/adviceBuilder/insuranceResearch/InsuranceResearchView.vue' /* webpackChunkName: "components/insurance-research-view" */).then(c => wrapFunctional(c.default || c))
export const PlatformResearchView = () => import('../../components/adviceBuilder/platformResearch/PlatformResearchView.vue' /* webpackChunkName: "components/platform-research-view" */).then(c => wrapFunctional(c.default || c))
export const StrategicModellingView = () => import('../../components/adviceBuilder/strategicModelling/StrategicModellingView.vue' /* webpackChunkName: "components/strategic-modelling-view" */).then(c => wrapFunctional(c.default || c))
export const EmailCreateDialog = () => import('../../components/organisationTools/email/EmailCreateDialog.vue' /* webpackChunkName: "components/email-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const GanttCreateContent = () => import('../../components/organisationTools/gantt/GanttCreateContent.vue' /* webpackChunkName: "components/gantt-create-content" */).then(c => wrapFunctional(c.default || c))
export const GanttCreateDialog = () => import('../../components/organisationTools/gantt/GanttCreateDialog.vue' /* webpackChunkName: "components/gantt-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const KanbanCreateCard = () => import('../../components/organisationTools/kanban/KanbanCreateCard.vue' /* webpackChunkName: "components/kanban-create-card" */).then(c => wrapFunctional(c.default || c))
export const KanbanCreateContent = () => import('../../components/organisationTools/kanban/KanbanCreateContent.vue' /* webpackChunkName: "components/kanban-create-content" */).then(c => wrapFunctional(c.default || c))
export const KanbanCreateDialog = () => import('../../components/organisationTools/kanban/KanbanCreateDialog.vue' /* webpackChunkName: "components/kanban-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const KanbanEditor = () => import('../../components/organisationTools/kanban/KanbanEditor.vue' /* webpackChunkName: "components/kanban-editor" */).then(c => wrapFunctional(c.default || c))
export const KanbanLinkBtn = () => import('../../components/organisationTools/kanban/KanbanLinkBtn.vue' /* webpackChunkName: "components/kanban-link-btn" */).then(c => wrapFunctional(c.default || c))
export const KanbanStageItem = () => import('../../components/organisationTools/kanban/KanbanStageItem.vue' /* webpackChunkName: "components/kanban-stage-item" */).then(c => wrapFunctional(c.default || c))
export const KanbanTemplateLinkBtn = () => import('../../components/organisationTools/kanban/KanbanTemplateLinkBtn.vue' /* webpackChunkName: "components/kanban-template-link-btn" */).then(c => wrapFunctional(c.default || c))
export const RequestsRequestCreateContent = () => import('../../components/organisationTools/requests/RequestCreateContent.vue' /* webpackChunkName: "components/requests-request-create-content" */).then(c => wrapFunctional(c.default || c))
export const RequestsRequestCreateDialog = () => import('../../components/organisationTools/requests/RequestCreateDialog.vue' /* webpackChunkName: "components/requests-request-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const RequestsRequestEditor = () => import('../../components/organisationTools/requests/RequestEditor.vue' /* webpackChunkName: "components/requests-request-editor" */).then(c => wrapFunctional(c.default || c))
export const RequestsRequestFormSection = () => import('../../components/organisationTools/requests/RequestFormSection.vue' /* webpackChunkName: "components/requests-request-form-section" */).then(c => wrapFunctional(c.default || c))
export const SharedFoldersCreateContent = () => import('../../components/organisationTools/sharedFolders/SharedFoldersCreateContent.vue' /* webpackChunkName: "components/shared-folders-create-content" */).then(c => wrapFunctional(c.default || c))
export const SharedFoldersCreateDialog = () => import('../../components/organisationTools/sharedFolders/SharedFoldersCreateDialog.vue' /* webpackChunkName: "components/shared-folders-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const SharedFoldersStatusButton = () => import('../../components/organisationTools/sharedFolders/SharedFoldersStatusButton.vue' /* webpackChunkName: "components/shared-folders-status-button" */).then(c => wrapFunctional(c.default || c))
export const TasksActionList = () => import('../../components/organisationTools/tasks/ActionList.vue' /* webpackChunkName: "components/tasks-action-list" */).then(c => wrapFunctional(c.default || c))
export const TasksAssociatedLinkBtn = () => import('../../components/organisationTools/tasks/AssociatedLinkBtn.vue' /* webpackChunkName: "components/tasks-associated-link-btn" */).then(c => wrapFunctional(c.default || c))
export const TasksComments = () => import('../../components/organisationTools/tasks/Comments.vue' /* webpackChunkName: "components/tasks-comments" */).then(c => wrapFunctional(c.default || c))
export const TasksConfirmationDialog = () => import('../../components/organisationTools/tasks/ConfirmationDialog.vue' /* webpackChunkName: "components/tasks-confirmation-dialog" */).then(c => wrapFunctional(c.default || c))
export const TasksDialogSwitcher = () => import('../../components/organisationTools/tasks/DialogSwitcher.vue' /* webpackChunkName: "components/tasks-dialog-switcher" */).then(c => wrapFunctional(c.default || c))
export const TasksRecurringConfirmationDialog = () => import('../../components/organisationTools/tasks/RecurringConfirmationDialog.vue' /* webpackChunkName: "components/tasks-recurring-confirmation-dialog" */).then(c => wrapFunctional(c.default || c))
export const TasksStatusButton = () => import('../../components/organisationTools/tasks/StatusButton.vue' /* webpackChunkName: "components/tasks-status-button" */).then(c => wrapFunctional(c.default || c))
export const TasksSubtaskCreationDialog = () => import('../../components/organisationTools/tasks/SubtaskCreationDialog.vue' /* webpackChunkName: "components/tasks-subtask-creation-dialog" */).then(c => wrapFunctional(c.default || c))
export const TasksSubtaskList = () => import('../../components/organisationTools/tasks/SubtaskList.vue' /* webpackChunkName: "components/tasks-subtask-list" */).then(c => wrapFunctional(c.default || c))
export const TasksTaskDialog = () => import('../../components/organisationTools/tasks/TaskDialog.vue' /* webpackChunkName: "components/tasks-task-dialog" */).then(c => wrapFunctional(c.default || c))
export const TasksTemplateTaskDialog = () => import('../../components/organisationTools/tasks/TemplateTaskDialog.vue' /* webpackChunkName: "components/tasks-template-task-dialog" */).then(c => wrapFunctional(c.default || c))
export const TasksWorkflowInterchangeDialog = () => import('../../components/organisationTools/tasks/WorkflowInterchangeDialog.vue' /* webpackChunkName: "components/tasks-workflow-interchange-dialog" */).then(c => wrapFunctional(c.default || c))
export const WorkflowActivityDialog = () => import('../../components/organisationTools/workflow/WorkflowActivityDialog.vue' /* webpackChunkName: "components/workflow-activity-dialog" */).then(c => wrapFunctional(c.default || c))
export const WorkflowCreateDialog = () => import('../../components/organisationTools/workflow/WorkflowCreateDialog.vue' /* webpackChunkName: "components/workflow-create-dialog" */).then(c => wrapFunctional(c.default || c))
export const WorkflowDeletedDialog = () => import('../../components/organisationTools/workflow/WorkflowDeletedDialog.vue' /* webpackChunkName: "components/workflow-deleted-dialog" */).then(c => wrapFunctional(c.default || c))
export const WorkflowEmailDialog = () => import('../../components/organisationTools/workflow/WorkflowEmailDialog.vue' /* webpackChunkName: "components/workflow-email-dialog" */).then(c => wrapFunctional(c.default || c))
export const WorkflowFileNoteDialog = () => import('../../components/organisationTools/workflow/WorkflowFileNoteDialog.vue' /* webpackChunkName: "components/workflow-file-note-dialog" */).then(c => wrapFunctional(c.default || c))
export const WorkflowInceptionDialog = () => import('../../components/organisationTools/workflow/WorkflowInceptionDialog.vue' /* webpackChunkName: "components/workflow-inception-dialog" */).then(c => wrapFunctional(c.default || c))
export const WorkflowInvoiceDialog = () => import('../../components/organisationTools/workflow/WorkflowInvoiceDialog.vue' /* webpackChunkName: "components/workflow-invoice-dialog" */).then(c => wrapFunctional(c.default || c))
export const WorkflowLinkBtn = () => import('../../components/organisationTools/workflow/WorkflowLinkBtn.vue' /* webpackChunkName: "components/workflow-link-btn" */).then(c => wrapFunctional(c.default || c))
export const WorkflowNode = () => import('../../components/organisationTools/workflow/WorkflowNode.vue' /* webpackChunkName: "components/workflow-node" */).then(c => wrapFunctional(c.default || c))
export const WorkflowNodeCard = () => import('../../components/organisationTools/workflow/WorkflowNodeCard.vue' /* webpackChunkName: "components/workflow-node-card" */).then(c => wrapFunctional(c.default || c))
export const WorkflowNodeDialog = () => import('../../components/organisationTools/workflow/WorkflowNodeDialog.vue' /* webpackChunkName: "components/workflow-node-dialog" */).then(c => wrapFunctional(c.default || c))
export const WorkflowNodeEditor = () => import('../../components/organisationTools/workflow/WorkflowNodeEditor.vue' /* webpackChunkName: "components/workflow-node-editor" */).then(c => wrapFunctional(c.default || c))
export const WorkflowRequestDialog = () => import('../../components/organisationTools/workflow/WorkflowRequestDialog.vue' /* webpackChunkName: "components/workflow-request-dialog" */).then(c => wrapFunctional(c.default || c))
export const WorkflowSinkNode = () => import('../../components/organisationTools/workflow/WorkflowSinkNode.vue' /* webpackChunkName: "components/workflow-sink-node" */).then(c => wrapFunctional(c.default || c))
export const WorkflowStatusBtn = () => import('../../components/organisationTools/workflow/WorkflowStatusBtn.vue' /* webpackChunkName: "components/workflow-status-btn" */).then(c => wrapFunctional(c.default || c))
export const WorkflowSubtaskList = () => import('../../components/organisationTools/workflow/WorkflowSubtaskList.vue' /* webpackChunkName: "components/workflow-subtask-list" */).then(c => wrapFunctional(c.default || c))
export const WorkflowTaskDialog = () => import('../../components/organisationTools/workflow/WorkflowTaskDialog.vue' /* webpackChunkName: "components/workflow-task-dialog" */).then(c => wrapFunctional(c.default || c))
export const DocumentEngineTableOptions = () => import('../../components/documentEngine/DocumentEngineTableOptions.vue' /* webpackChunkName: "components/document-engine-table-options" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderSet = () => import('../../components/documentEngine/documentBuilder/DocumentBuilderSet.vue' /* webpackChunkName: "components/document-builder-set" */).then(c => wrapFunctional(c.default || c))
export const DocumentBuilderView = () => import('../../components/documentEngine/documentBuilder/DocumentBuilderView.vue' /* webpackChunkName: "components/document-builder-view" */).then(c => wrapFunctional(c.default || c))
export const TemplateDocumentsView = () => import('../../components/documentEngine/templateDocuments/TemplateDocumentsView.vue' /* webpackChunkName: "components/template-documents-view" */).then(c => wrapFunctional(c.default || c))
export const TemplateSectionBuilder = () => import('../../components/documentEngine/templateSections/TemplateSectionBuilder.vue' /* webpackChunkName: "components/template-section-builder" */).then(c => wrapFunctional(c.default || c))
export const TemplateSectionSnippets = () => import('../../components/documentEngine/templateSections/TemplateSectionSnippets.vue' /* webpackChunkName: "components/template-section-snippets" */).then(c => wrapFunctional(c.default || c))
export const TemplateSectionsDialog = () => import('../../components/documentEngine/templateSections/TemplateSectionsDialog.vue' /* webpackChunkName: "components/template-sections-dialog" */).then(c => wrapFunctional(c.default || c))
export const TemplateSectionsView = () => import('../../components/documentEngine/templateSections/TemplateSectionsView.vue' /* webpackChunkName: "components/template-sections-view" */).then(c => wrapFunctional(c.default || c))
export const PageCardView = () => import('../../components/page/CardView.vue' /* webpackChunkName: "components/page-card-view" */).then(c => wrapFunctional(c.default || c))
export const PageIndexView = () => import('../../components/page/IndexView.vue' /* webpackChunkName: "components/page-index-view" */).then(c => wrapFunctional(c.default || c))
export const PageSingleCardView = () => import('../../components/page/SingleCardView.vue' /* webpackChunkName: "components/page-single-card-view" */).then(c => wrapFunctional(c.default || c))
export const PageSingleTableView = () => import('../../components/page/SingleTableView.vue' /* webpackChunkName: "components/page-single-table-view" */).then(c => wrapFunctional(c.default || c))
export const PageViewHeader = () => import('../../components/page/ViewHeader.vue' /* webpackChunkName: "components/page-view-header" */).then(c => wrapFunctional(c.default || c))
export const SharedIconsCheckboxMinus = () => import('../../components/shared/icons/CheckboxMinus.vue' /* webpackChunkName: "components/shared-icons-checkbox-minus" */).then(c => wrapFunctional(c.default || c))
export const SharedIconsCheckboxOff = () => import('../../components/shared/icons/CheckboxOff.vue' /* webpackChunkName: "components/shared-icons-checkbox-off" */).then(c => wrapFunctional(c.default || c))
export const SharedIconsCheckboxOn = () => import('../../components/shared/icons/CheckboxOn.vue' /* webpackChunkName: "components/shared-icons-checkbox-on" */).then(c => wrapFunctional(c.default || c))
export const SharedIconsChevron = () => import('../../components/shared/icons/Chevron.vue' /* webpackChunkName: "components/shared-icons-chevron" */).then(c => wrapFunctional(c.default || c))
export const SharedIconsClose = () => import('../../components/shared/icons/Close.vue' /* webpackChunkName: "components/shared-icons-close" */).then(c => wrapFunctional(c.default || c))
export const SharedLoadingCustomLoader = () => import('../../components/shared/loading/CustomLoader.vue' /* webpackChunkName: "components/shared-loading-custom-loader" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
